import { ClientOnly } from "remix-utils/client-only";
export const Emoji: React.FC<{ emojiId?: string | null | undefined }> = ({ emojiId }) => {
  if (!emojiId) return <></>;

  return (
    <ClientOnly>
      {() => {
        return (
          <div className="rounded-lg">
            {/* @ts-ignore */}
            <em-emoji id={emojiId} size="16px" />
          </div>
        );
      }}
    </ClientOnly>
  );
};
